<template>
  <div class="max-w-full mx-auto py-2 lg:px-2">
    <live-broadcasts />
    <featured-sponsors />
    <todays-schedule />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LiveBroadcasts from '@/components/dashboard/live/broadcasts.vue';
import FeaturedSponsors from '@/components/dashboard/sponsors/sponsors.vue';
import TodaysSchedule from '@/components/dashboard/schedule/today.vue';
export default {
  components: {
    TodaysSchedule,
    LiveBroadcasts,
    FeaturedSponsors
  },
  computed: {
    ...mapGetters('exhibitors', ['sponsors'])
  }
};
</script>
