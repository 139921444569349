<template>
  <div class="mb-12 w-full lg:w-3/4 xl:w-1/2">
    <h3 class="my-4 leading-5 text-lg uppercase text-gray-800">
      Todays Schedule
    </h3>
    <div class="">
      <div
        v-if="hasRows"
        class="space-y-4"
      >
        <meeting-card
          v-for="row in schedule"
          :key="row.id"
          :meeting="row"
          schedule
        />
      </div>
      <div v-else-if="scheduleLoaded">
        <a-alert type="info">
          Attendees may add meetings to their expo schedules on the Show Schedule page.
        </a-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
import MeetingCard from '@/components/meetings/card/card.vue';
export default {
  components: {
    MeetingCard
  },
  computed: {
    ...mapGetters('user', ['todaysSchedule', 'scheduleLoaded']),
    schedule() {
      return this.todaysSchedule;
    },
    hasRows() {
      return !isEmpty(this.schedule);
    }
  }
};
</script>