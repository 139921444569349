<template>
  <div
    v-if="!isEmpty(rows)"
    class="mb-12"
  >
    <h3 class="my-2 leading-5 text-lg uppercase text-gray-800">
      Watch Live Broadcasts
    </h3>
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <stream-card
        v-for="meeting in rows"
        :key="meeting.id"
        :meeting="meeting"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import StreamCard from '@/components/streams/card/card.vue';
import now from '@/utils/now';
import { slice, isEmpty } from '@/utils/lodash';
export default {
  components: {
    StreamCard
  },
  data: () => ({
    scheduleInterval: null
  }),
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapState('streams', ['streams']),
    rows() {
      return slice(this.streams, 0, 2);
    }
  },
  watch: {
    current: {
      handler: 'onCurrentConferenceChange'
    }
  },
  created() {
    this.onCurrentConferenceChange();
    this.setupInterval();
  },
  beforeUnmount() {
    this.clear();
    this.clearInterval();
  },
  methods: {
    isEmpty,
    ...mapActions('streams', ['all']),
    ...mapMutations('streams', { clear: 'CLEAR_STREAMS' }),
    setupInterval() {
      this.scheduleInterval = setInterval(() => this.fetch(), 30000);
    },
    clearInterval() {
      clearInterval(this.scheduleInterval);
    },
    async fetch() {
      await this.all(now.value);
    },
    async onCurrentConferenceChange() {
      if (this.current) {
        await this.all(now.value);
      }
    }
  }
};
</script>