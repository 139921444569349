<template>
  <div
    v-if="!isEmpty(sponsors)"
    class="mb-12"
  >
    <h3 class="my-4 leading-5 text-lg uppercase text-gray-800">
      Featured Exhibitors
    </h3>
    <div class="space-y-6 sm:grid grid-cols-1 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8 mb-16 mt-4">
      <sponsor-card
        v-for="sponsor in sponsors"
        :key="sponsor.id"
        :exhibitor="sponsor"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/utils/lodash';
import SponsorCard from '@/components/exhibitors/cards/featured.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    SponsorCard
  },
  computed: {
    ...mapGetters('exhibitors', ['sponsors']),
    ...mapGetters('conferences', ['current'])
  },
  async created() {
    await this.all(this.current);
  },
  methods: {
    isEmpty,
    ...mapActions('exhibitors', ['all'])
  }
};
</script>