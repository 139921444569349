<template>
  <div class="relative shadow-lg group hover:shadow-xl">
    <router-link :to="route">
      <div class="aspect-w-3 aspect-h-2">
        <img
          v-if="background"
          class="object-cover shadow-lg rounded-lg rounded-b-none"
          :src="background"
          alt=""
        >
        <slot name="logo">
          <div
            v-if="logo"
            class="absolute top-4 left-4 h-20 w-1/3 shadow-lg rounded-lg aspect-w-4 aspect-h-1"
            :class="{'bg-white': !dark, 'bg-black': dark}"
          >
            <img
              class="object-contain shadow-lg rounded-lg p-2"
              :src="logo"
              alt=""
            >
          </div>
        </slot>
      </div>
      <div class="bg-white w-full h-16 bottom-0 rounded-b-md">
        <slot
          name="title"
        >
          <div
            v-if="title"
            class="absolute bottom-7 left-3"
          >
            <span :class="['text-xl text-black truncate break-all']">{{ title }}</span>
          </div>
        </slot>
        <slot
          name="tags"
        >
          <div
            v-if="hasTags"
            class="absolute bottom-1 left-3"
          >
            <span :class="['text-base', {'text-gray-600': !dark, 'text-black': dark}]">{{ stringTags }}</span>
          </div>
        </slot>
      </div>
    </router-link>
  </div>
</template>

<script>
import { resize } from '@/utils/resize';
import { isEmpty, get, join, map } from '@/utils/lodash';
export default {
  props: {
    exhibitor: {
      type: Object,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    route() {
      return this.demo
        ? {}
        : {
            name: 'virtual.exhibitors.view',
            params: { slug: this.exhibitor.slug }
          };
    },
    hasTags() {
      return !isEmpty(this.tags);
    },
    tags() {
      return map(this.exhibitor.tags, 'name');
    },
    stringTags() {
      return join(this.tags, ', ');
    },
    title() {
      return this.exhibitor.title;
    },
    logo() {
      return get(this.exhibitor, 'card_logo.url');
    },
    background() {
      let path = get(this.exhibitor, 'card_background.path');
      let background = get(this.exhibitor, 'banner.path');

      if (!path && !background) {
        return false;
      }

      return resize(path ? path : background, 800, 800);
    }
  }
};
</script>